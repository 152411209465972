/**
 * TaoChuan Pro 业务配置
 * */
const env = process.env.NODE_ENV

const Setting = {
  /**
   * 基础配置
   * */
  // 网页标题的后缀
  titleSuffix: '乐考学堂',
  // 路由模式，可选值为 history 或 hash
  // 接口请求地址
  apiBaseURL: env === 'development' ? 'http://www.lekaoschool.com' : 'https://www.lekaoschool.com',
  // 附件地址
  oss: 'http://video.lekaoschool.com/',
  // 接口请求返回错误时，弹窗的持续时间，单位：秒
  modalDuration: 3,
  // 接口请求返回错误时，弹窗的类型，可选值为 Message 或 Notice
  errorModalType: 'Message'
}

export default Setting
